function defaultReportTemplate() {
    return {
        title: null,
        organizationId: 0,
        sheetIds: [],
        sheetRecordIds: [],
        price: null,
    }
}

const ReportUtils = {
    defaultReportTemplate: defaultReportTemplate,
    TITLE_MAX_LENGTH: 80,
    SHEET_MAX_COUNT: 5,
}

export default ReportUtils;