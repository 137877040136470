<template>
  <div class="page layout-vertical" v-if="reportTemplate">
    <div class="padding padding-large font-size-medium layout-inflexible">
      <span class="btn-report-list" @click="clickReportTemplateList">报告模板列表</span>
      <span>/{{ reportTemplate.title }}</span>
    </div>
    <div class="layout-flexible layout-vertical layout-center" style="overflow: hidden;">
      <div>
        <ys-input
            class="input-title"
            v-model="reportTemplate.title"
            size="extra-large"
            :maxlength="MAX_REPORT_TITLE"
            @input="handleTitleInput"
        ></ys-input>
      </div>
      <div style="height: 100%">
        <sheet-container
            :sheet-list="sheetList"
            :sheet-record-list="sheetRecordList"
            style="height: 100%;"
            mode="view"
            :read-only="false"
            @input="handleSheetRecordInput"
        ></sheet-container>
      </div>
    </div>
  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import AutosubmitHelper from "@/assets/javascript/autosubmit-helper";
import SheetContainer from "@/components/sheet/SheetContainer";
import YsButton from "@/components/wedigets/YsButton";
import YsInput from "@/components/wedigets/YsInput";
import ReportUtils from "@/assets/javascript/report-utils";

const TASK_SUMIBT_PUNCH = 1;

export default {
  name: "Report",
  components: {YsInput, YsButton, SheetContainer},
  mixins: [httpapi, AutosubmitHelper],
  props: {
    organizationId: Number,
  },
  data() {
    return {

      MAX_REPORT_TITLE: ReportUtils.TITLE_MAX_LENGTH,

      /**
       * 报告模板
       */
      reportTemplate: null,

      /**
       * 表单
       */
      sheetList: [],
      sheetRecordList: [],
    }
  },
  activated() {
    this.loadingCode++;
    this.loadReportTemplate();
  },
  methods: {
    clickReportTemplateList: function () {
      this.$router.replace({name: 'report_template_list'})
    },
    handleTitleInput: function () {
      this.createTask({
        tag: TASK_SUMIBT_PUNCH,
        params: this.reportTemplate,
        runFunc: this.submitReportTemplate,
      })
    },
    handleSheetRecordInput: function (sheetRecordList) {
      this.sheetRecordList = sheetRecordList;
      this.createTask({
        tag: TASK_SUMIBT_PUNCH,
        params: this.reportTemplate,
        runFunc: this.submitReportTemplate,
      })
    },
    isBusy: function () {
      return this.busyLoadingCode === this.loadingCode;
    },
    loadReportTemplate: function () {
      this.$reqGet({
        path: `/report/template/${this.$route.query.id}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            let reportTemplate = res.data;
            if (this.$route.query.isNew) delete reportTemplate.id;
            let sheetRecordList = reportTemplate.sheetRecordList;
            let sheetList = sheetRecordList.map(x => x.sheet);
            for (let sheetRecord of sheetRecordList) {
              delete sheetRecord.sheet;
            }
            this.reportTemplate = reportTemplate;
            this.sheetRecordList = sheetRecordList;
            this.sheetList = sheetList;
          })
          .catch(() => this.$message.error('加载失败'));
    },
    async submitReportTemplate(reportTemplate) {
      if (reportTemplate.id) {
        await this.editReportTemplate(reportTemplate);
      } else {
        await this.addReportTemplate(reportTemplate);
      }
    },
    addReportTemplate(reportTemplate) {
      for(let sheetRecord of this.sheetRecordList) {
        sheetRecord.id = null;
      }
      return this.$reqPostJSON({
        path: `/report/template/${this.organizationId}/${encodeURIComponent(reportTemplate.title)}`,
        data: {
          sheetRecordList: this.sheetRecordList
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            reportTemplate.id = res.data.id;
            for (let n = 0; n < res.data.sheetRecordList.length; n++) {
              this.sheetRecordList[n].id = res.data.sheetRecordList[n].id;
            }
            window.eventBus.$emit('notify:report_template_add', reportTemplate.id);
          })
          .catch(() => this.$message.error('保存失败'))
          .promise();
    },
    editReportTemplate(reportTemplate) {
      return this.$reqPut({
        path: `/report/template/${reportTemplate.id}/${encodeURIComponent(reportTemplate.title)}`,
        data: {
          sheetRecordList: this.sheetRecordList
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            for (let n = 0; n < res.data.sheetRecordList.length; n++) {
              this.sheetRecordList[n].id = res.data.sheetRecordList[n].id;
            }
          })
          .catch(() => this.$message.error('保存失败'))
          .promise();
    }
  }
}
</script>

<style scoped>

.page {
  height: 100vh;
}

.input-title {
  width: 600px;
}

.btn-report-list {
  color: #409EFF;
  cursor: pointer;
  user-select: none;
}

</style>